//我的抄送
<template>
  <Page>
    <template slot="search">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="料号" prop="code">
          <el-input placeholder="请输入" v-model="params.code"></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input placeholder="请输入" v-model="params.name"></el-input>
        </el-form-item>
        <el-form-item label="规格" prop="spec">
          <el-input placeholder="请输入" v-model="params.spec"></el-input>
        </el-form-item>
      </el-form>
    </template>
    <template slot="otherBtn">
      <el-button
        type="primary"
        plain
        @click="asynEvent"
        v-loading="asynLoading"
      >
        同步
      </el-button>
    </template>

    <div class="table-wrap">
      <el-table
        border
        :data="tableData"
        v-loading="loading"
        :row-class-name="tableRowClassName"
      >
        <el-table-column align="center" label="序号" type="index" width="60" />
        <el-table-column
          show-overflow-tooltip
          prop="code"
          label="料号"
          width="150"
        />
        <el-table-column show-overflow-tooltip prop="name" label="名称" />
        <el-table-column show-overflow-tooltip prop="spec" label="规格" />
        <el-table-column
          show-overflow-tooltip
          label="备注"
          width="120"
          :formatter="({ remark }) => remark || '-'"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="showCount"
          label="授权数量"
          width="100"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="totalCount"
          label="配件数量"
          width="100"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          label="状态"
          width="80"
        >
          <template slot-scope="{ row }">
            {{ row.status | statusFilter }}
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          label="变更时间"
          width="160"
          prop="syncTime"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          label="操作"
          width="80"
        >
          <template slot-scope="{ row }">
            <el-button
              size="medium"
              type="text"
              v-auth="'detail'"
              @click="toDetail(row)"
            >
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.pageNo"
      @change="getData"
    />
  </Page>
</template>

<script>
import watchParamsGetData from '../../mixins/watchParamsGetData'
export default {
  components: {},
  data() {
    return {
      params: {},
      asynLoading: false
    }
  },
  filters: {
    statusFilter: function (value) {
      const obj = { 1: '正常', 2: '差异', 3: '下线' }
      return obj[value] || value || '-'
    }
  },
  mixins: [watchParamsGetData],
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (row.status === 2) {
        return 'danger-row'
      }
      if (row.status === 3) {
        return 'grey-row'
      }
      return ''
    },
    async asynEvent() {
      try {
        const { code = '' } = this.params
        if (!code.trim()) {
          this.$message.warning('请输入料号')
          return
        }
        this.asynLoading = true
        const res = await this.$api.bom.bomSync({ code })
        if (res) {
          this.$message.success('同步成功')
          this.getData()
        } else {
          this.$message.error('料号不正确')
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.asynLoading = false
      }
    },
    async getData() {
      try {
        this.loading = true
        let params = { ...this.params }
        const { list, total } = await this.$api.bom.getBomPage(params)
        this.tableData = (list || []).map(item => {
          const auditStatusStr = this.$constant.auditStatus.find(
            _ => _.value == item.status
          )?.label
          const typeStr = this.$constant.applyType.find(
            _ => _.value == item.attach
          )?.label
          return { ...item, auditStatusStr, typeStr }
        })
        this.total = total
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async toDetail(row) {
      this.$store.commit('setState', { keyName: 'bomDetails', val: row })
      this.$router.push({
        path: '/bomManage/detail',
        query: {
          root: row.id
        }
      })
    }
  }
}
</script>
